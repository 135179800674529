import config from '../config/config'
import { axios } from './index'
import axiosLibrary from 'axios'

let organizationManagementUrl = config.baseUrl + '/user/api/v1/org/management'

export default {
  //企业注册
  register(payload) {
    return axios.post(`${organizationManagementUrl}/register`, payload)
  }
}
