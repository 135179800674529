let area = {
  province: [
    '河北省',
    '山西省',
    '辽宁省',
    '吉林省',
    '黑龙江省',
    '江苏省',
    '浙江省',
    '安徽省',
    '福建省',
    '江西省',
    '山东省',
    '河南省',
    '湖北省',
    '湖南省',
    '广东省',
    '海南省',
    '四川省',
    '贵州省',
    '云南省',
    '陕西省',
    '甘肃省',
    '青海省',
    '台湾省',
    '内蒙古自治区',
    '广西壮族自治区',
    '西藏自治区',
    '宁夏回族自治区',
    '新疆维吾尔自治区',
    '北京市',
    '天津市',
    '上海市',
    '重庆市',
    '香港特别行政区',
    '澳门特别行政区'
  ],
  city: [
    ['石家庄市', '邯郸市', '邢台市', '保定市', '张家口市', '承德市', '秦皇岛市', '唐山市', '沧州市', '廊坊市', '衡水市'],
    ['太原市', '大同市', '阳泉市', '晋城市', '朔州市', '晋中市', '忻州市', '吕梁市', '临汾市', '运城市', '长治市'],
    ['沈阳市', '大连市', '丹东市', '葫芦岛市', '盘锦市', '阜新市', '朝阳市', '铁岭市', '锦州市', '抚顺市', '鞍山市', '辽阳市', '本溪市', '营口市'],
    ['长春市', '吉林市', '四平市', '通化市', '白山市', '松原市', '白城市', '延边朝鲜族自治州', '辽源市'],
    [
      '齐齐哈尔市',
      '鹤岗市',
      '双鸭山市',
      '鸡西市',
      '大庆市',
      '伊春市',
      '牡丹江市',
      '佳木斯市',
      '七台河市',
      '黑河市',
      '绥化市',
      '大兴安岭地区',
      '哈尔滨市'
    ],
    ['南京市', '徐州市', '连云港市', '淮安市', '宿迁市', '盐城市', '扬州市', '泰州市', '南通市', '镇江市', '常州市', '无锡市', '苏州市'],
    ['宁波市', '杭州市', '温州市', '嘉兴市', '湖州市', '绍兴市', '金华市', '衢州市', '丽水市', '台州市', '舟山市'],
    [
      '铜陵市',
      '合肥市',
      '淮南市',
      '淮北市',
      '芜湖市',
      '蚌埠市',
      '马鞍山市',
      '安庆市',
      '黄山市',
      '滁州市',
      '阜阳市',
      '亳州市',
      '宿州市',
      '池州市',
      '六安市',
      '宣城市'
    ],
    ['福州市', '厦门市', '三明市', '莆田市', '泉州市', '漳州市', '龙岩市', '宁德市', '南平市'],
    ['南昌市', '景德镇市', '萍乡市', '新余市', '九江市', '鹰潭市', '上饶市', '宜春市', '抚州市', '吉安市', '赣州市'],
    [
      '济南市',
      '青岛市',
      '淄博市',
      '枣庄市',
      '东营市',
      '潍坊市',
      '烟台市',
      '威海市',
      '莱芜市',
      '德州市',
      '临沂市',
      '聊城市',
      '滨州市',
      '菏泽市',
      '日照市',
      '泰安市',
      '济宁市'
    ],
    [
      '郑州市',
      '开封市',
      '洛阳市',
      '平顶山市',
      '鹤壁市',
      '新乡市',
      '安阳市',
      '濮阳市',
      '许昌市',
      '三门峡市',
      '南阳市',
      '商丘市',
      '周口市',
      '信阳市',
      '济源市',
      '漯河市',
      '焦作市',
      '驻马店市'
    ],
    [
      '武汉市',
      '襄阳市',
      '十堰市',
      '荆州市',
      '宜昌市',
      '孝感市',
      '黄冈市',
      '咸宁市',
      '恩施土家族苗族自治州',
      '荆门市',
      '随州市',
      '潜江市',
      '天门市',
      '仙桃市',
      '神农架林区',
      '鄂州市',
      '黄石市'
    ],
    [
      '长沙市',
      '株洲市',
      '湘潭市',
      '衡阳市',
      '邵阳市',
      '岳阳市',
      '常德市',
      '张家界市',
      '郴州市',
      '益阳市',
      '永州市',
      '怀化市',
      '娄底市',
      '湘西土家族苗族自治州'
    ],
    [
      '广州市',
      '深圳市',
      '珠海市',
      '汕头市',
      '韶关市',
      '河源市',
      '梅州市',
      '惠州市',
      '汕尾市',
      '东莞市',
      '中山市',
      '江门市',
      '佛山市',
      '阳江市',
      '湛江市',
      '茂名市',
      '肇庆市',
      '云浮市',
      '清远市',
      '潮州市',
      '揭阳市'
    ],
    [
      '海口市',
      '儋州市',
      '琼海市',
      '万宁市',
      '东方市',
      '三亚市',
      '文昌市',
      '五指山市',
      '临高县',
      '澄迈县',
      '定安县',
      '屯昌县',
      '白沙黎族自治县',
      '琼中黎族苗族自治县',
      '陵水黎族自治县',
      '保亭黎族苗族自治县',
      '乐东黎族自治县',
      '三沙市',
      '昌江黎族自治县'
    ],
    [
      '成都市',
      '自贡市',
      '攀枝花市',
      '泸州市',
      '绵阳市',
      '德阳市',
      '广元市',
      '遂宁市',
      '内江市',
      '乐山市',
      '宜宾市',
      '广安市',
      '南充市',
      '达州市',
      '巴中市',
      '雅安市',
      '眉山市',
      '资阳市',
      '阿坝藏族羌族自治州',
      '甘孜藏族自治州',
      '凉山彝族自治州'
    ],
    ['贵阳市', '六盘水市', '遵义市', '铜仁市', '毕节市', '安顺市', '黔西南布依族苗族自治州', '黔东南苗族侗族自治州', '黔南布依族苗族自治州'],
    [
      '昆明市',
      '曲靖市',
      '玉溪市',
      '昭通市',
      '普洱市',
      '临沧市',
      '保山市',
      '丽江市',
      '文山壮族苗族自治州',
      '红河哈尼族彝族自治州',
      '西双版纳傣族自治州',
      '楚雄彝族自治州',
      '大理白族自治州',
      '德宏傣族景颇族自治州',
      '怒江傈僳族自治州',
      '迪庆藏族自治州'
    ],
    ['西安市', '铜川市', '宝鸡市', '咸阳市', '延安市', '汉中市', '榆林市', '商洛市', '安康市', '渭南市'],
    [
      '兰州市',
      '金昌市',
      '白银市',
      '嘉峪关市',
      '平凉市',
      '庆阳市',
      '武威市',
      '张掖市',
      '酒泉市',
      '甘南藏族自治州',
      '临夏回族自治州',
      '定西市',
      '天水市',
      '陇南市'
    ],
    ['西宁市', '海东市', '海北藏族自治州', '黄南藏族自治州', '海南藏族自治州', '海西蒙古族藏族自治州', '果洛藏族自治州', '玉树藏族自治州'],
    ['台北市', '新北市', '桃园市', '台中市', '台南市', '高雄市', '基隆市', '新竹市', '嘉义市'],
    [
      '赤峰市',
      '呼和浩特市',
      '包头市',
      '乌海市',
      '乌兰察布市',
      '锡林郭勒盟',
      '呼伦贝尔市',
      '鄂尔多斯市',
      '巴彦淖尔市',
      '阿拉善盟',
      '兴安盟',
      '通辽市'
    ],
    ['南宁市', '柳州市', '桂林市', '梧州市', '北海市', '防城港市', '钦州市', '贵港市', '玉林市', '贺州市', '百色市', '河池市', '来宾市', '崇左市'],
    ['拉萨市', '山南市', '昌都市', '日喀则市', '林芝市', '阿里地区', '那曲市'],
    ['银川市', '石嘴山市', '固原市', '中卫市', '吴忠市'],
    [
      '乌鲁木齐市',
      '克拉玛依市',
      '石河子市',
      '吐鲁番市',
      '哈密市',
      '和田地区',
      '阿克苏地区',
      '喀什地区',
      '克孜勒苏柯尔克孜自治州',
      '巴音郭楞蒙古自治州',
      '昌吉回族自治州',
      '博尔塔拉蒙古自治州',
      '伊犁哈萨克自治州',
      '塔城地区',
      '阿勒泰地区',
      '五家渠市',
      '阿拉尔市',
      '图木舒克市',
      '铁门关市',
      '昆玉市'
    ]
  ]
}
export { area }
