<template>
  <div class="main">
    <div class="header" :class="{ 'is-fixed': isFixed }" ref="header">
      <div class="container">
        <img :src="isFixed ? require('@assets/home/logo-fixed.png') : require('@assets/home/logo.png')" alt="" />
        <div>
          <el-button @click="$router.push('/login')">
            <div>
              <YTIcon :href="'#icon-guanliduan'" />
              <p>管理登录</p>
            </div>
          </el-button>
          <el-button @click="jumpToUser">
            <div>
              <YTIcon :href="'#icon-yonghu2'" />
              <p>用户登录</p>
            </div>
          </el-button>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="container ">
        <div class="middle">
          <h1>源图数字人才管理平台</h1>
          <p>
            <span>精准<span class="bold">测评</span></span>
            <span>精准<span class="bold">培训</span></span>
            <span>精准<span class="bold">识别</span></span>
            <span>精准<span class="bold" style="margin: 0;">任用</span></span>
          </p>
          <el-button @click="jumpToRegister">欢迎试用</el-button>
        </div>
        <div class="bottom">
          <div v-for="(item, index) in bannerList" :key="index" class="item">
            <YTIcon :href="item.icon"></YTIcon>
            <p>{{ item.label }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container closed-loop">
      <p>我们致力于打造全方位</p>
      <h1>人才管理闭环</h1>
      <div class="loop-list">
        <div
          v-for="(item, index) in loopList"
          :key="index"
          class="item"
          :style="{ marginRight: (index + 1) % 4 === 0 ? '0' : '25px', paddingTop: item.top }"
        >
          <img :src="item.icon" alt="" />
          <div>{{ item.label }}</div>
        </div>
      </div>
    </div>
    <div class="separator">
      <div class="container">
        <img :src="require('@assets/home/separator-1.png')" alt="" />
        <p>满足多种情景需求的一体化人才管理解决方案</p>
      </div>
    </div>
    <div class="evaluation">
      <div class="container title">
        <h1>人才测评</h1>
        <p>为人才测评提供全面的服务与解决方案</p>
        <div class="count-list">
          <div v-for="(item, index) in evaluationList" :key="index" class="item">
            <p>{{ item.label }}</p>
            <div>
              <span>{{ item.count }}</span
              >+
            </div>
          </div>
        </div>
        <div class="menu-list">
          <div v-for="(item, index) in evaluationMenu" :key="index" class="item">
            <img :src="item.img" alt="" :style="{ marginTop: item.top, marginBottom: item.bottom }" />
            <h2>{{ item.label }}</h2>
            <div>{{ item.description }}</div>
            <!--            <el-button type="primary" size="small">查看样例</el-button>-->
          </div>
        </div>
      </div>
    </div>
    <div class="report">
      <div style="padding-top: 100px">
        <h1>测评报告</h1>
        <p>从整体、技能、个人三个维度多角度的测评结果分析，给出详实的测评报告</p>
        <div class="list-show">
          <div class="list-hidden" :style="{ left: `${switchLength[activeBannerIndex]}px` }">
            <template v-for="(item, index) in reportLength">
              <div
                class="report-img"
                :key="index"
                ref="reportImg"
                :style="{ opacity: activeBannerIndex === item - 1 || activeBannerIndex === item - 2 ? 1 : 0 }"
                @mouseover="showActive(item, false)"
                @mouseout="showActive(item, true)"
              >
                <img
                  :src="require(`@assets/home/report${activeHoverIndex === item ? '_active' : ''}${item === reportLength ? 1 : item}.png`)"
                  alt=""
                  width="100%"
                />
              </div>
            </template>
          </div>
        </div>
        <div style="display: flex; justify-content: center;margin: 60px auto 100px auto">
          <div
            class="change-btn"
            v-for="(item, index) in reportLength - 1"
            :class="{ active: activeBannerIndex === index }"
            @mouseenter="reportChange(true, index)"
            @mouseover="stopChange"
            @mouseout="revertChange"
          ></div>
        </div>
      </div>
    </div>
    <div class="container title">
      <h1>学习&发展</h1>
      <p>为人才测评提供全面的服务与解决方案</p>
      <div class="count-list">
        <div v-for="(item, index) in developList" :key="index" class="item">
          <p>{{ item.label }}</p>
          <div>
            <span>{{ item.count }}</span
            >+
          </div>
        </div>
      </div>
      <div class="dev-list">
        <div v-for="(item, index) in developMenu" :key="index" class="item">
          <img :src="item.img" alt="" :style="{ margin: item.margin }" />
          <p>{{ item.label }}</p>
          <div>{{ item.description }}</div>
        </div>
      </div>
    </div>
    <div class="evaluation">
      <div class="container title">
        <h1>人才画像</h1>
        <p>为人才测评提供全面的服务与解决方案</p>
        <div class="portrayal">
          <img :src="require('@assets/home/portrayal.png')" style="margin: 0 25px 52px -45px" alt="" />
          <div>
            <h2>精准的人才画像</h2>
            <h2>和团队画像的挖掘与分析</h2>
            <p>精细描述个人能力水平，精准定位团队整体水平。为企业匹配高水平人 才、高潜力人才，提供个人画像分析以及团队画像分析。</p>
            <ul>
              <li>
                <img :src="require('@assets/home/portrayal-1.png')" alt="" />
                <span>画像定位，培养&提升人才</span>
              </li>
              <li>
                <img :src="require('@assets/home/portrayal-2.png')" alt="" />
                <span>数据分析整合&挖掘追踪</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="separator">
      <div class="container">
        <img :src="require('@assets/home/separator-2.png')" alt="" />
        <p>支持公有云、私有云及本地部署ISO 27001认证</p>
      </div>
    </div>
    <div class="container cooperation">
      <div style="width: 359px;margin-right: 54px; flex-shrink: 0">
        <h2>与我们建立信任的</h2>
        <h1>合作企业</h1>
        <p>源图将人才画像置于解决方案的中心地位。我们通过提供全面的人才管理技术，帮助深具全球化思维的领先企业在中国吸引、发展、提升人才。</p>
        <el-button type="primary">成功案例</el-button>
      </div>
      <ul>
        <li v-for="(item, index) in cooperationList" :key="index">
          <img :src="item.logo" alt="" />
        </li>
      </ul>
    </div>
    <div class="register title" id="registerForm">
      <h1>开始试用</h1>
      <p>点击快速注册，即将开启试用</p>
      <el-form ref="form" :model="registerForm" :rules="ruleValidate" label-width="90px" class="yt-form">
        <el-form-item label="企业名称" prop="name">
          <el-input v-model="registerForm.name" placeholder="请输入企业全称" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="registerForm.phone" placeholder="请输入手机号作为账号" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="registerForm.email" placeholder="请输入邮箱" />
        </el-form-item>
        <el-form-item label="联系人" prop="adminName">
          <el-input v-model="registerForm.adminName" placeholder="请输入联系人名称" />
        </el-form-item>
        <el-form-item label="地区" style="text-align: left;">
          <el-select v-model="registerForm.province" placeholder="请选择省" style="margin-right: 12px" @change="changeCity">
            <el-option v-for="(item, index) in provinces" :value="item" :key="index" :label="item" />
          </el-select>
          <el-select v-model="registerForm.city" placeholder="请选择市" @change="selectCity" :disabled="registerForm.province === ''">
            <el-option v-for="(item, index) in cities" :value="item" :key="index" :label="item" />
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">提交注册</el-button>
    </div>
    <div class="footer">
      <div class="container">
        <ul>
          <li>
            <span style="opacity: 0.9">友情链接:</span>
            <span v-for="(item, index) in friendship" :key="index">{{ item.label }}</span>
          </li>
          <li>
            <span style="opacity: 0.9">分公司:</span>
            <span v-for="(item, index) in branch" :key="index">{{ item.label }}</span>
          </li>
        </ul>
        <span style="cursor: pointer" @click="jumpTo">
          © 南京源图信息技术有限公司 版权所有 苏ICP备18061615号-3
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import { area } from '@util/area'
import organizationManagementApi from '@api/organizationManagement'
export default {
  name: 'Home',
  components: { YTIcon },
  data() {
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!reg.test(value)) {
        callback(new Error('企业名称不能全为空格'))
      } else {
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      if (!/^1(3\d{2}|4[14-9]\d|5([0-35689]\d|7[1-79])|66\d|7[2-35-8]\d|8\d{2}|9[13589]\d)\d{7}$/.test(value)) {
        callback(new Error('请输入正确格式的手机号码'))
      } else {
        callback()
      }
    }
    return {
      isFixed: false,
      bannerList: [
        {
          label: 'IEEE SWEBOK V3',
          icon: '#icon-huojiang1'
        },
        {
          label: 'SEEK',
          icon: '#icon-jiangbei'
        },
        {
          label: 'IEEE-CS',
          icon: '#icon-huojiang2'
        },
        {
          label: 'ACM',
          icon: '#icon-huojiangbiaoshi'
        },
        {
          label: '5I CUP',
          icon: '#icon-huojiang'
        },
        {
          label: 'IEEE',
          icon: '#icon-huojiang2'
        }
      ],
      loopList: [
        {
          label: '人才测评',
          icon: require('@assets/home/loop-1.png'),
          top: '28px'
        },
        {
          label: '学习&发展',
          icon: require('@assets/home/loop-2.png'),
          top: '29px'
        },
        {
          label: '人才画像',
          icon: require('@assets/home/loop-3.png'),
          top: '30px'
        },
        {
          label: '人才盘点',
          icon: require('@assets/home/loop-4.png'),
          top: '28px'
        },
        {
          label: '职业路径规划',
          icon: require('@assets/home/loop-5.png'),
          top: '28px'
        },
        {
          label: '候选人关系管理',
          icon: require('@assets/home/loop-6.png'),
          top: '29px'
        },
        {
          label: '团队统计分析',
          icon: require('@assets/home/loop-7.png'),
          top: '32px'
        },
        {
          label: '团队画像',
          icon: require('@assets/home/loop-1.png'),
          top: '28px'
        }
      ],
      evaluationList: [
        {
          label: '知识点数量',
          count: 0,
          max: 2000,
          timer: null
        },
        {
          label: '岗位数量',
          count: 0,
          max: 20,
          timer: null
        },
        {
          label: '能力',
          count: 0,
          max: 800,
          timer: null
        },
        {
          label: '试卷数量',
          count: 0,
          max: 50,
          timer: null
        },
        {
          label: '题目数量',
          count: 0,
          max: 6000,
          timer: null
        }
      ],
      evaluationMenu: [
        {
          label: '丰富的知识体系',
          img: require('@assets/home/evaluation-1.png'),
          top: '42px',
          bottom: '49px',
          description: '覆盖4个教育基础知识域、11个实践知识域和7个辅助学科领域。'
        },
        {
          label: '完善的测评方式',
          img: require('@assets/home/evaluation-2.png'),
          top: '34px',
          bottom: '42px',
          description: '智能出卷、手动出卷不同的出卷方式，快速构建测评试卷。'
        },
        {
          label: '精准的测评报告',
          img: require('@assets/home/evaluation-3.png'),
          top: '18px',
          bottom: '37px',
          description: '测评报告能力体系，岗位匹配，知识点分布，一目了然。'
        }
      ],
      reportTimer: null,
      switchLength: [0, -570, -1140],
      activeBannerIndex: 0, //激活的报告
      activeHoverIndex: '', //悬停激活的报告
      reportLength: 4,
      developList: [
        {
          label: '课程数量',
          count: 0,
          max: 50,
          timer: null
        },
        {
          label: '章节数量',
          count: 0,
          max: 500,
          timer: null
        },
        {
          label: '覆盖知识点数量',
          count: 0,
          max: 2000,
          timer: null
        },
        {
          label: '课后练习数量',
          count: 0,
          max: 1000,
          timer: null
        },
        {
          label: '课程总时长',
          count: 0,
          max: 600,
          timer: null
        }
      ],
      developMenu: [
        {
          label: '全行业',
          img: require('@assets/home/develop-1.png'),
          description: '9999+热门行业覆盖，一站式平台',
          margin: '40px 0'
        },
        {
          label: '高质量',
          img: require('@assets/home/develop-2.png'),
          description: '南京大学资深软院老师上课',
          margin: '41px 0 29px'
        },
        {
          label: '真场景',
          img: require('@assets/home/develop-3.png'),
          description: '课程内容与业界应用相挂钩',
          margin: '39px 79px 22px 51px'
        },
        {
          label: '深入浅出',
          img: require('@assets/home/develop-4.png'),
          description: '课程从易到难，有广度更有深度',
          margin: '37px 0 34px'
        },
        {
          label: '快更新',
          img: require('@assets/home/develop-5.png'),
          description: '专业老师全职更新最新业界技术',
          margin: '36px 0 41px'
        },
        {
          label: '严保障',
          img: require('@assets/home/develop-6.png'),
          description: '课程、课后习题专人审核',
          margin: '14px 0 43px'
        }
      ],
      cooperationList: [
        {
          logo: require('@assets/home/cooperation-1.png')
        },
        {
          logo: require('@assets/home/cooperation-2.png')
        },
        {
          logo: require('@assets/home/cooperation-3.png')
        },
        {
          logo: require('@assets/home/cooperation-4.png')
        },
        {
          logo: require('@assets/home/cooperation-5.png')
        },
        {
          logo: require('@assets/home/cooperation-6.png')
        },
        {
          logo: require('@assets/home/cooperation-7.png')
        },
        {
          logo: require('@assets/home/cooperation-8.png')
        },
        {
          logo: require('@assets/home/cooperation-9.png')
        },
        {
          logo: require('@assets/home/cooperation-10.png')
        },
        {
          logo: require('@assets/home/cooperation-11.png')
        },
        {
          logo: require('@assets/home/cooperation-12.png')
        },
        {
          logo: require('@assets/home/cooperation-13.png')
        },
        {
          logo: require('@assets/home/cooperation-14.png')
        },
        {
          logo: require('@assets/home/cooperation-15.png')
        },
        {
          logo: require('@assets/home/cooperation-16.png')
        },
        {
          logo: require('@assets/home/cooperation-17.png')
        },
        {
          logo: require('@assets/home/cooperation-18.png')
        },
        {
          logo: require('@assets/home/cooperation-19.png')
        },
        {
          logo: require('@assets/home/cooperation-20.png')
        }
      ],
      registerForm: {
        name: '',
        phone: '',
        email: '',
        adminName: '',
        province: '',
        city: ''
      },
      ruleValidate: {
        name: [
          { required: true, message: '企业名称不能为空' },
          { max: 50, message: '企业名称长度不能超过50个字符' },
          { min: 2, message: '企业名称长度不小于2个字符' },
          { validator: validateName, trigger: 'blur' }
        ],
        adminName: { max: 20, message: '联系人名称长度不能超过20个字符' },
        email: {
          type: 'email',
          message: '请输入正确格式的邮箱, 登录名@域名',
          trigger: 'blur'
        },
        phone: [
          { required: true, message: '手机号码不能为空' },
          { validator: validatePhone, trigger: 'blur' }
        ]
      },
      cities: [],
      provinces: area.province,
      friendship: [
        {
          label: '学码匠',
          url: ''
        },
        {
          label: '智慧讲堂',
          url: ''
        },
        {
          label: '能力评测',
          url: ''
        }
      ],
      branch: [
        {
          label: '南京',
          url: ''
        },
        {
          label: '上海',
          url: ''
        },
        {
          label: '武汉',
          url: ''
        },
        {
          label: '无锡',
          url: ''
        },
        {
          label: '马鞍山',
          url: ''
        },
        {
          label: '北京',
          url: ''
        },
        {
          label: '浙江',
          url: ''
        }
      ]
    }
  },
  mounted() {
    document.getElementsByTagName('body')[0].style.height = 'auto'
    window.addEventListener('scroll', this.handleHeaderFixed)
    this.reportTimer = setInterval(this.change, 5000)
  },
  beforeDestroy() {
    document.getElementsByTagName('body')[0].style.height = '100%'
    window.removeEventListener('scroll', this.handleHeaderFixed)
    clearInterval(this.reportTimer)
  },
  methods: {
    handleHeaderFixed() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      let offsetTop = this.$refs.header.offsetTop // 要滚动到顶部吸附的元素的偏移量
      this.isFixed = scrollTop > offsetTop // 如果滚动到顶部了，this.isFixed就为true
      if (scrollTop > 1400) {
        this.makeTimer(this.evaluationList)
      }
      if (scrollTop > 2000) {
        this.makeTimer(this.developList)
      }
    },
    jumpToRegister() {
      document.querySelector('#registerForm').scrollIntoView(true)
    },
    jumpToUser() {
      location.href = window.g.userUrl
    },
    change() {
      if (this.activeBannerIndex < 2) {
        this.activeBannerIndex++
      } else {
        this.activeBannerIndex = 0
      }
      this.reportChange(false)
    },
    showActive(item, isLeave) {
      if (isLeave) {
        this.activeHoverIndex = ''
        this.reportTimer = setInterval(this.change, 5000)
      } else {
        this.activeHoverIndex = item
        clearInterval(this.reportTimer)
        this.reportTimer = null
      }
    },
    //切换报告按钮
    reportChange(isHover, index) {
      if (isHover) {
        this.activeBannerIndex = index
      }
      this.$refs.reportImg.forEach(item => {
        item.scrollTop = 0
      })
    },
    stopChange() {
      clearInterval(this.reportTimer)
      this.reportTimer = null
    },
    revertChange() {
      this.reportTimer = setInterval(this.change, 5000)
    },
    makeTimer(list) {
      list.forEach(item => {
        item.timer = setInterval(() => {
          if (item.count < item.max) {
            item.count += 50
          } else {
            item.count = item.max
            clearInterval(item.timer)
            item.timer = null
          }
        }, 1)
      })
    },
    changeCity(data) {
      this.$forceUpdate()
      this.registerForm.city = ''
      this.cities = area.city[area.province.indexOf(data)]
    },
    selectCity() {
      this.$forceUpdate()
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let payload = { ...this.registerForm }
          payload.address = payload.province + ' ' + payload.city
          organizationManagementApi
            .register(payload)
            .then(res => {
              if (res.res === true) {
                this.$message.success('提交成功, 请等待审核')
                this.$refs.form.resetFields()
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    jumpTo() {
      window.location.href = 'https://www.miit.gov.cn/'
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 1120px;
  margin: 0 auto;
  overflow: hidden;
}
@media screen and (max-width: 1120px) {
  .main {
    width: 1280px;
    background-color: #fff;
  }
  .container {
    width: 1271px;
    padding: 0 20px;
  }
}
.header {
  width: 100%;
  height: 88px;
  transition: all 0.2s ease-in-out;
  position: fixed;
  top: 0;
  .container {
    .flexStyle(flex, space-between);
    margin-top: 26px;
    .el-button {
      width: 120px;
      height: 36px;
      padding: 0 20px;
      color: #448bff;
      border-radius: 18px !important;
      border-color: #448bff;
    }
  }
  &.is-fixed {
    background-color: #ffffff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    z-index: 999;
    .el-button {
      color: #ffffff;
      background-color: #448bff !important;
      &:hover {
        color: #448bff;
        background-color: #ffffff !important;
      }
    }
  }
}
.banner {
  height: 675px;
  background: url('~@/assets/home/header.png') no-repeat no-repeat center center;
  .container {
    height: 100%;
  }
  .middle {
    margin-top: 198px;
    margin-bottom: 76px;
    h1 {
      font-size: 40px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 19px;
    }
    p {
      font-size: 36px;
      color: #ffffff;
      margin-bottom: 50px;
      opacity: 0.9;
      span {
        letter-spacing: 5px;
      }
      .bold {
        font-weight: 800;
        margin-right: 25px;
      }
    }
    .el-button {
      width: 160px;
      height: 48px;
      color: #ffffff;
      border-radius: 24px !important;
      background-color: transparent;
      border-color: #ffffff;
      opacity: 0.8;
      &:hover {
        color: #ffffff !important;
        background-color: #448bff !important;
        border-color: #448bff !important;
        opacity: 1;
      }
    }
  }
  .bottom {
    .flexStyle(flex);
    .item {
      margin-right: 143px;
      color: #ffffff;
      p {
        margin-top: 12px;
        font-size: @medium;
        font-weight: bold;
        opacity: 0.9;
      }
      svg {
        font-size: 40px;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
.closed-loop {
  p {
    margin-top: 85px;
    margin-bottom: 10px;
    font-size: 38px;
    color: #666666;
    letter-spacing: 5px;
  }
  h1 {
    font-size: 38px;
    margin-bottom: 95px;
  }
  .loop-list {
    .flexStyle();
    flex-wrap: wrap;
    margin-bottom: 80px;
    .item {
      .flexStyle(flex, space-between);
      flex-direction: column;
      width: 239px;
      height: 166px;
      margin-right: 25px;
      margin-bottom: 25px;
      padding-bottom: 19px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      &:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      }
      div {
        font-size: @default;
        font-weight: 800;
      }
    }
  }
}
.separator {
  height: 364px;
  background: url('~@/assets/home/separator.png') center center no-repeat no-repeat;
  .container {
    .flexStyle(flex, space-between, flex-start);
    height: 100%;
    padding-top: 71px;
    text-align: left;
    vertical-align: middle;
    p {
      margin-top: 79px;
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 1px;
      color: #ffffff;
    }
  }
}
.evaluation {
  background-color: #f9fbff;
  .menu-list {
    .flexStyle(flex, space-between, flex-start);
    padding-bottom: 100px;
    .item {
      width: 350px;
      height: 500px;
      background: #ffffff;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
      &:nth-child(2) {
        margin-top: 40px;
      }
      &:hover {
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
        color: #448bff;
        .el-button {
          color: #448bff;
          background-color: #fff !important;
          box-shadow: 0 3px 20px rgba(60, 100, 209, 0.7);
        }
      }
      h2 {
        font-size: 23px;
        font-weight: 800;
        letter-spacing: 2px;
        margin-bottom: 12px;
      }
      div {
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        opacity: 0.9;
        padding: 0 37px;
        margin-bottom: 30px;
      }
      .el-button {
        height: 38px;
        background: #448bff;
        box-shadow: 0 3px 10px rgba(60, 100, 209, 0.5);
        border-radius: 19px !important;
      }
    }
  }
}
.report {
  margin-bottom: 50px;
  background-color: #ffffff;
  overflow: hidden;
  h1 {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    opacity: 0.9;
  }
  .list-show {
    margin: 50px auto 0;
    width: 1120px;
    height: 800px;
    position: relative;
    .list-hidden {
      .flexStyle();
      position: absolute;
      left: 0;
      transition: all ease-in-out 0.3s;
      .report-img {
        width: 550px;
        height: 800px;
        margin: 20px 20px 20px 0;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
        overflow-y: auto;
        &:hover {
          box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  .change-btn {
    float: left;
    width: 60px;
    height: 10px;
    margin: 0 15px;
    border-radius: 20px;
    opacity: 0.5;
    background-color: #d4d9e2;
    &.active {
      background-color: #0064ff;
    }
    &:hover {
      cursor: pointer;
      background-color: #0064ff;
    }
  }
}
//隐藏测评报告垂直滚动条
.report-img::-webkit-scrollbar {
  display: none;
}
.title {
  overflow: visible;
  padding-top: 100px;
  background-color: #f9fbff;
  h1 {
    font-size: 38px;
    font-weight: 800;
    margin-bottom: 12px;
  }
  p {
    font-size: @medium;
    font-weight: 500;
    letter-spacing: 1px;
    opacity: 0.9;
  }
  .count-list {
    .flexStyle(flex, space-between);
    margin: 60px 0;
    .item {
      text-align: left;
      div {
        .flexStyle(flex, flex-start);
        font-size: 20px;
        span {
          font-weight: 800;
          font-size: 30px;
          margin-right: 5px;
        }
      }
    }
  }
}
.dev-list {
  .flexStyle(flex, space-between);
  flex-wrap: wrap;
  margin-bottom: 39px;
  .item {
    width: 289px;
    height: 344px;
    margin-bottom: 30px;
    &:hover {
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    }
    p {
      line-height: 32px;
      font-size: 23px;
      font-weight: 800;
    }
    div {
      font-size: @medium;
      font-weight: 500;
    }
  }
}
.portrayal {
  .flexStyle(flex, flex-start);
  text-align: left;
  h2 {
    font-size: 30px;
    font-weight: 800;
    letter-spacing: 2px;
  }
  p {
    margin: 30px 0;
  }
  li {
    .flexStyle(flex, flex-start);
    margin-bottom: 30px;
    img {
      margin-right: 16px;
    }
    span {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }
}
.cooperation {
  .flexStyle(flex, flex-start, flex-start);
  text-align: left;
  padding-top: 100px;
  padding-bottom: 80px;
  h1 {
    font-size: 48px;
    font-weight: 800;
    margin: 15px 0 30px;
  }
  h2 {
    font-size: 36px;
    font-weight: 400;
    margin-top: 5px;
  }
  p {
    font-size: @medium;
    letter-spacing: 1px;
    margin-bottom: 60px;
  }
  .el-button {
    width: 115px;
    height: 38px;
    background: #448bff !important;
    border-radius: 19px !important;
    &:hover {
      color: #448bff;
      background-color: #fff !important;
    }
  }
  ul {
    .flexStyle(flex, space-between);
    flex-wrap: wrap;
    li {
      .flexStyle(flex);
      width: 151px;
      height: 123px;
      border-radius: 6px;
      &:hover {
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
.register {
  .flexStyle(flex, flex-start);
  flex-direction: column;
  height: 824px;
  background: url('~@/assets/home/register.png') center center;
  margin-bottom: 42px;
  p {
    margin-bottom: 60px;
  }
  .el-form-item {
  }
  ::v-deep .el-form-item__label {
    line-height: 44px;
    padding-right: 22px;
  }
  .el-input {
    width: 541px;
    ::v-deep .el-input__inner {
      height: 44px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: none;
    }
  }
  ::v-deep .el-select {
    .el-input {
      width: 264px;
      .el-input__inner {
        height: 44px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        border: none;
      }
    }
  }
  .el-button {
    width: 115px;
    height: 38px;
    margin-top: 60px;
    background: #448bff !important;
    border-radius: 19px !important;
  }
}
.footer {
  height: 100px;
  background-color: #171e30;
  font-size: @medium;
  font-weight: 500;
  color: #ffffff;
  @media screen and (min-width: 1480px) {
    .container {
      width: 1480px;
    }
  }
  @media screen and (max-width: 1120px) {
    .container {
      width: 1271px;
      padding: 0 20px;
    }
  }
  span {
    opacity: 0.6;
  }
  ul {
    .flexStyle(flex, space-between);
    margin: 20px 0;
    span {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
